<template>
  <div>
  <b-card title="Completed Job Order">    
    <b-row>   
      <b-col cols="12">
        <b-row align-h="end" style="margin-bottom:15px;">
          <b-col cols="4">
            <b-form-input
              v-model="search"
              @input="debounceSearch"
              type="search"
              placeholder="Type to Search"
              style="float: right;"
            />
        </b-col>
        </b-row>
        <b-row style="margin-bottom:10px" class="show-on-mobile">
          <b-col>
            <b-form-checkbox
              v-model="stackedStatus"
              value="md"
              unchecked-value="false"
            >
              Stacked Table
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :filter="search"
                  hover
                  responsive
                  small
                  head-variant="dark"
                  outlined
                  :items="jobOrders"
                  :fields="headers"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :stacked="stackedStatus"
                  show-empty
                >
                  <template #cell(type)="row">
                    {{ formatType(row.item.props.type) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon"/>
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="success"
                          size="sm"
                          :to="{name:'job-order-view', params:{id:row.item.id}}"
                          
                          style="margin-right:10px"
                          ><feather-icon size="1x" icon="EyeIcon"
                        /></b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
      </b-row>
      </b-col>
        <b-row>
          <b-col cols="4" class="pt-1">
            <b-form-group
              label="Data Per Page"
              label-for="per-page-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                @input="queryJobOrder()"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pt-1">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              @change="onPageChange"
              :total-rows="totalRows"
              first-number
              last-number
              class="float-right"
              aria-controls="user-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>
import { mapActions } from "vuex";
import { userAccess } from "@/utils/utils.js";
export default {
  data() {
    return {
      stackedStatus: "md",
      showSpinner: false,
      headers: [
        {
          key: "orderNumber",
          label: "Job Order Number",
          filterable: true,
          sortable: false,
        },
        // { key: 'orderNumber', label: 'Order Number', filterable: true, sortable: true },
        { key: "drumID", label: "From Batch", filterable: true, sortable: false },
        { key: "type", label: "Type", filterable: true, sortable: false },
        { key: "status", label: "Status", filterable: true, sortable: false },
        { key: "actions", label: "Actions" },
      ],
      //sort direction list
      directions: [
        { key: false, label: "Asc", sortable: false },
        { key: true, label: "Desc", sortable: false },
      ],
      workorderNumModal: false,
      workorderNumber: null,
      jobContext: null,
      search: null,
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      buttonState: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      promptOption: false,
      dataWarehouse: "",
      promptDetail: false,
      dataApproval: "",
      modalConfirmCancelJob : false,
      modalDisabledCancelJob : false,

      //for show jobdetails
      showJobModal: false,
      detailModal: {
        id: "detail-modal-bom",
        title: "",
        content: {
          // jobOrderNumber:'',
          name: "",
          type: "",
          createdAt: "",
          updatedAt: "",
        },
      },
    };
  },
  mounted() {
    this.queryJobOrder();
    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageCompletedJoborderManufacture")) {
      this.perPage = this.$session.get("perPageCompletedJoborderManufacture");
    }
    if (this.$session.has("sortByCompletedJoborderManufacture")) {
      this.sortBy = this.$session.get("sortByCompletedJoborderManufacture");
    }
    if (this.$session.has("sortDescCompletedJoborderManufacture")) {
      this.sortDesc = this.$session.get("sortDescCompletedJoborderManufacture");
    }
  },

  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageCompletedJoborderManufacture", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByCompletedJoborderManufacture", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescCompletedJoborderManufacture", sortDescNew);
    },
  },

  methods: {
    ...mapActions({
      getJobOrders: "joborder/getCompletedJobOrders",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryJobOrder(){
        this.getJobOrders({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },

      onPageChange(page) {
        this.currentPage = page;
        this.queryJobOrder();
      },

      
    formatType(value){
      switch(value) {
        case "Direct Sale":
          return "Direct Sale - 直销"
        case "Cutting":
          return "Cutting - 剪线"
        case "Recoil":
          return "Recoil - 卷线换轴"
        case "Direct Short Length":
          return "Direct Short Length - 剪短线"
      }
    },
    // detail(item, index, event) {
    //   this.$store
    //     .dispatch("joborder/getJobOrderId", { id: item.id })
    //     .then((res) => {
    //       var d = this.$store.getters["joborder/getJobOrderId"];
    //       this.detailModal.title = "Detail Job Order";
    //       (this.detailModal.id = d.id),
    //         (this.detailModal.content = {
    //           orderNumber: d.orderNumber,
    //           type: d.props.type,
    //           items: d.item.map((x) => {
    //             return {
    //               name: x.item.name,
    //               pickList: x.pickList.id,
    //               orderNumber: x.orderNumber,
    //               quantity: x.quantity,
    //             };
    //           }),
    //           jobStart: d.props.JS ? new Date(d.props.JS):'no date',
    //           jobEnd: d.props.JE ? new Date(d.props.JE):'no date',
    //           mamaDrumPickingStart: d.props.MDS ? new Date(d.props.MDS):'no date',
    //           mamaDrumPickingEnd: d.props.MDE ? new Date(d.props.MDE):'no date',
    //           mamaDrumSetupStart: d.props.MSS ? new Date(d.props.MSS):'no date',
    //           mamaDrumSetupEnd: d.props.MSE  ? new Date(d.props.MSE):'no date',
    //           createdAt: d.createdAt,
    //           updatedAt: d.updatedAt,
    //         });
    //       this.showJobModal = true;
    //     });
    // },

    // add spinner while loading on searching data process
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },

    simplyfiedItems(items) {
      return items.reduce((prev, next) => {
        if (next === null || next === undefined) {
          return prev;
        }

        let humanName = next.detail || next.name;

        return [...prev, humanName];
      }, []);
    },
  },
  computed: {
    jobOrders() {
      return this.$store.getters["joborder/getCompletedJobOrders"]
    },
    permission() {
      return userAccess("completed job order");
    },
    rows() {
      return this.jobOrders.length;
    },
  },
};
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
